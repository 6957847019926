export const CHAPCHE_API_URL = 'BACKEND_API_URL';

export const PROFILE_TAB = 'profile';
export const USERS_TAB = 'user';
export const PRODUCT_TAB = 'product';
export const RECIPE_TAB = 'recipe';
export const PREFERENCE_TAB = 'preference';

export const ADMIN_ROLE = 'admin';
export const GUEST_ROLE = 'guest';

export const ADMIN_CONSOLE_BUSINESS_OBJECT = 'admin_console';
export const CABINET_BUSINESS_OBJECT = 'cabinet';

export const ERROR_WRONG_EMAIL_TYPE = 'email';
export const ERROR_WRONG_ROLE_TYPE = 'roleId';

export const CREATE_PERMISSIONS = 'createPermission';
export const READ_PERMISSIONS = 'readPermission';
export const UPDATE_PERMISSIONS = 'updatePermission';
export const DELETE_PERMISSIONS = 'deletePermission';

export const PAGE_TAB_NAME_LIST = 'list';
export const PAGE_TAB_NAME_NEW = 'new';

export const LOCALE_EN = 'en';
export const LOCALE_RU = 'ru';

export const ONE_MINUTE_NUMBER = 60000;
export const ONE_HOUR_NUMBER = 60;
export const ONE_DAY = 24 * 60 * 60 * 1000;

export const SORTING_DIRECTION_ASC = 'asc';
export const SORTING_DIRECTION_DESC = 'desc';

export const MOUSE_DOWN_EVENT = 'mousedown';
export const KEY_UP_EVENT = 'keyup';
export const CHANGE_VISIBILITY_EVENT = 'visibilitychange';
export const VISIBILITY_STATE_VISIBLE = 'visible';

export const ERROR_TYPE_LOGIN = 'login';
export const ERROR_TYPE_PASSWORD = 'password';
export const ERROR_WRONG_PHONE_TYPE = 'phone';

export const PREFERENCE_PHRASE = 'phrase';

export const FORM_TAB_NAME = 'form';
export const ITEM_TAB_NAME = 'item';
export const PROPERTY_TAB_NAME = 'property';
export const PHRASE_TAB_NAME = 'phrase';
export const MEAL_TAB_NAME = 'meal';
export const GROUP_TAB_NAME = 'group';

export const ACTIVE_MODAL_ITEM_EMAIL = 'email';
export const ACTIVE_MODAL_ITEM_PASSWORD = 'password';

export const PERMISSION_LEVEL_COMPONENT = 'component';
export const PERMISSION_LEVEL_BUSINESS_OBJECT = 'business_object';

export const POPUP_KEY_DELETE_PRODUCT = 'delete-product';
export const POPUP_KEY_DELETE_PROPERTY = 'delete-property';
export const POPUP_KEY_DELETE_FORM = 'delete-form';
export const POPUP_KEY_CREATE_FORM = 'create-form';
export const POPUP_KEY_CREATE_PROPERTY = 'create-property';
export const POPUP_KEY_CREATE_PRODUCT = 'create-product';
export const POPUP_KEY_ADD_PROPERTY = 'add-property';
export const POPUP_KEY_DELETE_PREFERENCE = 'delete-preference';
export const POPUP_KEY_CREATE_INGREDIENT = 'create-ingredient';
export const POPUP_KEY_RESTORE_PRODUCT = 'restore-product';
export const POPUP_KEY_RESTORE_PROPERTY = 'restore-property';
export const POPUP_KEY_RESTORE_FORM = 'restore-form';
export const POPUP_KEY_ASSIGN_USER = 'assign-user';
export const POPUP_KEY_ASSIGN_GROUP = 'assign-group';
export const POPUP_KEY_DELETE_RECIPE = 'delete-recipe';
export const POPUP_KEY_CREATE_RECIPE = 'create-recipe';
export const POPUP_KEY_ADD_INGREDIENT_TO_RECIPE = 'add-ingredient-to-recipe';
export const POPUP_KEY_DELETE_INGREDIENT = 'delete-ingredient';
export const POPUP_KEY_DELETE_PRODUCT_PROPERTY = 'delete-product-property';
export const POPUP_KEY_DELETE_PREFERENCE_GROUP = 'delete-preference-group';
export const POPUP_KEY_DELETE_PHRASE = 'delete-phrase';

export const PROPERTY_TYPE_NUMBER = 'number';
export const PROPERTY_TYPE_SELECT = 'select';
export const PROPERTY_TYPE_BOOLEAN = 'boolean';
export const PROPERTY_TYPE_STRING = 'string';

export const PHRASE_STATUS_CREATED = 'created';
export const PHRASE_STATUS_IN_PROGRESS = 'in-progress';

export const RECIPE_TYPE_KASHA = 'kasha';
export const RECIPE_TYPE_SNACK = 'snack';
export const RECIPE_TYPE_BOWL = 'bowl';
export const RECIPE_TYPE_SOUP = 'soup';
export const RECIPE_TYPE_BREAD = 'bread';
export const RECIPE_TYPE_SMOOTHIES = 'smoothies';
export const RECIPE_TYPE_GRANOLA = 'granola';

export const RECIPE_TYPES = [
    RECIPE_TYPE_KASHA,
    RECIPE_TYPE_SNACK,
    RECIPE_TYPE_BOWL,
    RECIPE_TYPE_SOUP,
    RECIPE_TYPE_BREAD,
    RECIPE_TYPE_SMOOTHIES,
    RECIPE_TYPE_GRANOLA,
];

export const RECIPE_INGREDIENT_TYPE_PRODUCT = 'product';
export const RECIPE_INGREDIENT_TYPE_CEREAL_MIX = 'cereal-mix';

export const RECIPE_CHARACTERISTIC_TASTE = "taste";
export const RECIPE_CHARACTERISTIC_TASTE_SWEET = "taste-sweet";
export const RECIPE_CHARACTERISTIC_TASTE_SALTY = "taste-salty";
export const RECIPE_CHARACTERISTIC_TASTE_SOUR = "taste-sour";
export const RECIPE_CHARACTERISTIC_TASTE_SPICY = "taste-spicy";
export const RECIPE_CHARACTERISTIC_TASTE_BITTER = "taste-bitter";
export const RECIPE_CHARACTERISTIC_TASTE_UMAMI = "taste-umami";

export const RECIPE_CHARACTERISTIC_TASTES = [
    RECIPE_CHARACTERISTIC_TASTE_SWEET,
    RECIPE_CHARACTERISTIC_TASTE_SALTY,
    RECIPE_CHARACTERISTIC_TASTE_SOUR,
    RECIPE_CHARACTERISTIC_TASTE_SPICY,
    RECIPE_CHARACTERISTIC_TASTE_BITTER,
    RECIPE_CHARACTERISTIC_TASTE_UMAMI,
];

export const PREFERENCE_TYPE_PRODUCT = 'product';
export const PREFERENCE_TYPE_RECIPE = 'recipe';
export const PREFERENCE_TYPE_RECIPE_CHARACTERISTIC = 'recipe-characteristic';
export const PREFERENCE_TYPE_PROPERTY = 'property';
export const PREFERENCE_TYPE_CEREAL_MIX = 'cereal-mix';

export const PREFERENCE_TYPES = [
    PREFERENCE_TYPE_PRODUCT,
    PREFERENCE_TYPE_RECIPE,
    PREFERENCE_TYPE_RECIPE_CHARACTERISTIC,
    PREFERENCE_TYPE_PROPERTY,
    PREFERENCE_TYPE_CEREAL_MIX,
];

export const PRODUCT_FORM_GRAIN = 'grain';

export const ITEM_COUNT_SMALL_WIDTH = 9;
export const ITEM_COUNT_FULL_WIDTH = 8;
export const PRODUCT_COUNT_SMALL_WIDTH = 8;
export const PRODUCT_COUNT_FULL_WIDTH = 10;

export const DEFAULT_PHRASES_COUNT = 50;
export const SMALL_PART_PHRASES_COUNT = 10;

export const CHECK_ITEM_CODE_REG_EXP = /^[A-Za-z0-9-_]+$/gm;
export const DIGITS_REG_EXP = /^[\.,0-9]*$/;
export const DATE_REG_EXP = /^\d*\.?\d*$/;
export const NON_DIGITS_REG_EXP = /\D/g;

export const FILTER_TYPE_CHECKBOX = 'checkbox';
export const FILTER_TYPE_SELECT = 'select';
export const FILTER_TYPE_MAX_AND_MIN = 'maxAndMin';
export const FILTER_NAME_IS_DELETED = 'isDeleted';
export const FILTER_NAME_IS_NOT_VALID = 'isNotValid';
export const FILTER_GROUP_NAME_STATUS = 'status';

export const PAGINATION_ITEM_TYPE_THREE_DOTS = 'three-dots';
export const PAGINATION_ITEM_TYPE_PAGE_NUMBER = 'page-number';
export const PAGINATION_ITEM_TYPE_NEXT_PAGE = 'next-page';
export const PAGINATION_ITEM_TYPE_LAST_PAGE = 'last-page';

export const COMMIT_FRONTEND = 'LAST_COMMIT_FRONTEND';
export const COMMIT_BACKEND = 'LAST_COMMIT_BACKEND';

export const CHAPCHE_WEBSOCKET_URL = 'WEBSOCKET_API_URL';

export const YANDEX_BROWSER = 'YaBrowser';
export const SAFARI_BROWSER = 'Safari';

export const PREFERENCE_GROUP_ACTIVITY_PERIOD_ALWAYS = 'always';
export const PREFERENCE_GROUP_ACTIVITY_PERIOD_SELECTED = 'activityPeriods';

export const DATE_START_LABEL = 'from';
export const DATE_STOP_LABEL = 'to';
export const DATE_START_KEY = 'dateStart';
export const DATE_STOP_KEY = 'dateStop';

export const currentPermissions = [
    {
        key: CREATE_PERMISSIONS,
        title: 'Создание',
    },
    {
        key: READ_PERMISSIONS,
        title: 'Чтение',
    },
    {
        key: UPDATE_PERMISSIONS,
        title: 'Редактирование',
    },
    {
        key: DELETE_PERMISSIONS,
        title: 'Удаление',
    },
    {
        key: 'viewAllPermission',
        title: 'Просмотр всех',
    },
    {
        key: 'modifyAllPermission',
        title: 'Изменение всех',
    },
];

export const AUTH_PERMISSION = 'auth';

export const I18N_CODE_VALIDATION_ERROR = 'app.page.product.checkFormError';
export const I18N_CODE_EXISTS_ERROR = 'app.page.product.checkCodeExists';
export const I18N_CODE_DELETED_PRODUCT_ERROR = 'app.page.product.checkCodeDeleted';
export const I18N_CODE_DELETED_FORM_ERROR = 'app.page.product.form.checkCodeDeleted';
export const I18N_CODE_DELETED_PROPERTY_ERROR = 'app.page.product.property.checkCodeDeleted';

export const TABLET_WIDTH = 1024;
export const SMALL_WEB_SCREEN_WIDTH = 1456;

export const NOTIFICATION_TYPE_INFO = 'info';
export const NOTIFICATION_TYPE_SUCCESS = 'success';
export const NOTIFICATION_TYPE_WARNING = 'warning';

export const NOTIFICATION_EVENT_TYPE_PHRASES_GENERATED = 'phrases-generated';
export const NOTIFICATION_EVENT_TYPE_PHRASES_UPDATED_FAIL = 'preference-phrase-updated-fail';
export const NOTIFICATION_EVENT_TYPE_PREFERENCE_UPDATE_FAIL = 'preference-updated-fail';

export const PRODUCT_CATEGORY_ANY = 'any';
