import React, { useEffect, useState } from 'react';
import { preferenceSaveService } from '../../services/preferenceSaveService';
import { propertiesStorage } from '../../services/propertiesStorage';
import ProductPropertyNumberValue from '../productsTab/products/ProductPropertyNumberValue';
import ProductPropertyInput from '../productsTab/products/ProductPropertyInput';
import ProductPropertyBoolean from '../productsTab/products/ProductPropertyBoolean';
import ProductPropertySelect from '../productsTab/products/ProductPropertySelect';
import { createSelectPreferencePropertyService } from '../../services/preferenceSelectService';
import {
    PREFERENCE_TYPE_PROPERTY,
    PREFERENCE_TYPE_RECIPE_CHARACTERISTIC,
    PROPERTY_TYPE_BOOLEAN,
    PROPERTY_TYPE_NUMBER,
    PROPERTY_TYPE_SELECT,
    PROPERTY_TYPE_STRING, RECIPE_CHARACTERISTIC_TASTE,
    RECIPE_CHARACTERISTIC_TASTES,
} from '../../constant';
import { i18n } from '../../i18n';
import SelectHandler from '../sharedComponents/SelectHandler';

const findPropertyByCode = (itemCode) => propertiesStorage.getItems(true).find(it => it.propertyName === itemCode);

const buildRecipeCharacteristicTastes = () => {
    return RECIPE_CHARACTERISTIC_TASTES.map(it => {
        return {
            id: it,
            name: i18n.get(`recipe.scheme.characteristic.taste.${it}`),
        };
    });
};

const getPreferenceValueTitle = () => {
    if(preferenceSaveService.getPreference().preferenceItemCodes &&
        preferenceSaveService.getPreference().preferenceItemCodes.length &&
        preferenceSaveService.getPreference().preferenceItemCodes[0].itemCode === RECIPE_CHARACTERISTIC_TASTE &&
        preferenceSaveService.getPreference().preferenceItemCodes[0].value) {
        return i18n.get(`recipe.scheme.characteristic.taste.${preferenceSaveService.getPreference().preferenceItemCodes[0].value}`);
    } else {
        return '';
    }
};

const PreferenceStructureValue = () => {
    const [preferenceTypeIsChanged, setPreferenceTypeIsChanged] = useState(null);
    const [propertiesStorageIsReady, setPropertiesStorageIsReady] = useState(false);
    const changePreferenceType = () => {
        setPreferenceTypeIsChanged(prev => !prev);
    };
    const findCurrentValueType = () => {
        const property = findPropertyByCode(preferenceSaveService.getProperty().itemCode);
        if(property) {
            switch (property.propertyType) {
            case PROPERTY_TYPE_NUMBER: {
                return (
                    <div className="product__form-block">
                        <ProductPropertyNumberValue 
                            propertyId={null} 
                            fieldName={'minValue'} 
                            fieldValue={preferenceSaveService.getProperty().minValue}
                            itemSaveService={preferenceSaveService} />
                        <ProductPropertyNumberValue 
                            propertyId={null} 
                            fieldName={'maxValue'} 
                            fieldValue={preferenceSaveService.getProperty().maxValue}
                            itemSaveService={preferenceSaveService} />
                    </div>
                );
            }
            case PROPERTY_TYPE_STRING: {
                return <ProductPropertyInput propertyId={null} itemSaveService={preferenceSaveService} />;
            }
            case PROPERTY_TYPE_BOOLEAN: {
                return <ProductPropertyBoolean propertyId={null} itemSaveService={preferenceSaveService} />;
            }
            case PROPERTY_TYPE_SELECT: {
                return <>
                    <span className="preference__form-value">{i18n.get('product.scheme.property.code.valueOfData')}</span>
                    <ProductPropertySelect selectWithTokensManager={createSelectPreferencePropertyService(property.id)}/>
                </>;
            }
            default: {
                return null;
            }}
        }
    };

    const updatePreferenceValue = (value) => {
        preferenceSaveService.updatePreferencePropertyValue('value',value);
    };

    const changePropertiesList = () => {
        setPropertiesStorageIsReady(propertiesStorage.getItems(true).length > 0);
    };

    useEffect(() => {
        if (preferenceSaveService.getPreference().preferenceType === PREFERENCE_TYPE_PROPERTY) {
            propertiesStorage.registerFiltersChangingObserver(changePropertiesList);
            return () => propertiesStorage.unRegisterFiltersChangingObserver(changePropertiesList);
        } else if (preferenceSaveService.getPreference().preferenceType === PREFERENCE_TYPE_RECIPE_CHARACTERISTIC) {
            preferenceSaveService.registerItemUpdateObserver(changePreferenceType);
            return () => preferenceSaveService.unRegisterItemUpdateObserver(changePreferenceType);
        }
    }, []);

    if (preferenceSaveService.getPreference().preferenceType === PREFERENCE_TYPE_PROPERTY) {
        return (
            <React.Fragment key={propertiesStorageIsReady + 'preferencePropertyValue'}>
                {findCurrentValueType()}
            </React.Fragment>
        );
    } else if (preferenceSaveService.getPreference().preferenceType === PREFERENCE_TYPE_RECIPE_CHARACTERISTIC) {
        return (
            <React.Fragment key={propertiesStorageIsReady + 'preferencePropertyValue'}>
                <SelectHandler
                    selectedValue={getPreferenceValueTitle()}
                    optionsList={buildRecipeCharacteristicTastes()}
                    sendSelectedValue={updatePreferenceValue}
                />
            </React.Fragment>
        );
    } else {
        return null;
    }
};

export default PreferenceStructureValue;
