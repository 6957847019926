export const LOGOUT_REQUEST = '/api/v1/auth/logout/';
export const LOGIN_REQUEST = '/api/v1/auth/login/';
export const RESET_PASSWORD_REQUEST = '/api/v1/auth/password/reset/';
export const CHANGE_PASSWORD_REQUEST = '/api/v1/auth/password/request/';
export const GET_USER_REQUEST = '/api/v1/user/';
export const GET_CURRENT_USER_REQUEST = '/api/v1/user/current/';
export const GET_APP_ROLES = '/api/v1/appRole/';
export const PRODUCT_URL = '/api/v1/product/';
export const DELETE_PRODUCT = '/api/v1/product/';
export const PROPERTIES_URL = '/api/v1/productScheme/property/';
export const GET_SOURCES = '/api/v1/productScheme/source/';
export const FORMS_URL = '/api/v1/productScheme/form/';
export const RECIPES_URL = '/api/v1/recipe/';
export const PREFERENCE_URL = '/api/v1/preference/';
export const ASSIGN_USER_PREFERENCE = '/api/v1/userPreference/';
export const LOCALES_URL = '/api/v1/locale/';
export const PREFERENCE_GROUP_URL = '/api/v1/preferenceScheme/group/';
export const RECIPE_SCHEME_URL = '/api/v1/recipeScheme/ingredient/';
export const PHRASE_URL_PART = '/phrase/';
export const CATEGORY_URL_PART = '/category/';
export const FORM_URL_PART = '/form/';
export const PROPERTY_URL_PART = '/property/';

export const getFetchRequest = (url, successCallback, errorCallback, withoutJSON = false) => {
    const headers = {
        "Content-Type": "application/json", 
    };
    fetch(`${url}`, {
        credentials: 'include',
        method: 'GET',
        headers,
    }).then(response => {
        if(!withoutJSON) {
            return response.json();
        } else {
            return response;
        }
    }).then(response => {
        successCallback(response);
    }).catch(e => {
        errorCallback(e);
    });
};

export const postFetchRequest = (url, body, successCallback, errorCallback, withoutJSON = false) => {
    const headers = {
        "Content-Type": "application/json", 
    };
    fetch(`${url}`, {
        credentials: 'include',
        method: 'POST',
        headers,
        body: JSON.stringify(body),
    }).then(response => {
        if(body && !withoutJSON) {
            return response.json();
        } else {
            return response;
        }
    }).then(response => {
        successCallback(response);
    }).catch(e => {
        errorCallback(e);
    });
};

export const putFetchRequest = (url, body, successCallback, errorCallback, withoutJSON = false) => {
    const headers = {
        "Content-Type": "application/json", 
    };
    fetch(`${url}`, {
        credentials: 'include',
        method: 'PUT',
        headers,
        body: JSON.stringify(body),
    }).then(response => {
        if(body && !withoutJSON) {
            return response.json();
        } else {
            return response;
        }
    }).then(response => {
        successCallback(response);
    }).catch(e => {
        errorCallback(e);
    });
};

export const deleteFetchRequest = (url, successCallback, errorCallback, body = null) => {
    const headers = {
        "Content-Type": "application/json", 
    };
    fetch(`${url}`, {
        credentials: 'include',
        method: 'DELETE',
        headers,
        body: body ? JSON.stringify(body) : null,
    }).then(response => {
        successCallback(response);
    }).catch(e => {
        errorCallback(e);
    });
};

export const getSyncFetchRequest = async (url) => {
    const headers = {
        "Content-Type": "application/json", 
    };
    const result = await fetch(`${url}`, {
        credentials: 'include',
        method: 'GET',
        headers,
    });
    const response = await result.json();
    return response;
};
