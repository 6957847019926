import { checkItemLocaleCode } from "../components/helper";
import { updateValueSevice } from "../components/sharedComponents/updateValueService";
import {
    RECIPE_CHARACTERISTIC_TASTE,
    RECIPE_CHARACTERISTIC_TASTE_BITTER,
    RECIPE_CHARACTERISTIC_TASTE_SALTY,
    RECIPE_CHARACTERISTIC_TASTE_SOUR,
    RECIPE_CHARACTERISTIC_TASTE_SPICY,
    RECIPE_CHARACTERISTIC_TASTE_SWEET,
    RECIPE_CHARACTERISTIC_TASTE_UMAMI,
} from "../constant";
import { i18n } from "../i18n";
import { RECIPES_URL, deleteFetchRequest, getFetchRequest, postFetchRequest } from "../store/requests";
import {errorCallback, makeUrl, successCallbackEmpty} from "../util";

const removeElementFromArray = (elements, conditionBeInArray) => {
    const result = [];
    elements.forEach((it) => {
        if (conditionBeInArray(it)) {
            result.push(it);
        }
    });
    return result;
};

const recipeTasteCharacteristics = [
    RECIPE_CHARACTERISTIC_TASTE_SWEET,
    RECIPE_CHARACTERISTIC_TASTE_SALTY,
    RECIPE_CHARACTERISTIC_TASTE_SOUR,
    RECIPE_CHARACTERISTIC_TASTE_SPICY,
    RECIPE_CHARACTERISTIC_TASTE_BITTER,
    RECIPE_CHARACTERISTIC_TASTE_UMAMI,
];

const isRecipeCharacteristicActive = (recipe, characteristicCategory, characteristicValue) => {
    return !(recipe.characteristics.find(it => it.value === characteristicValue && it.category === characteristicCategory) === undefined);
};

export const recipeSaveService = {
    currentRecipe: {},
    lastRecipeUpdateTime: null,
    recipeUpdateObservers: [],
    loadRecipe(recipeId, callback) {
        try {
            getFetchRequest(makeUrl([RECIPES_URL, recipeId, '/']), (response) => {
                this.currentRecipe = response;
                getFetchRequest(makeUrl([RECIPES_URL, recipeId, '/ingredient/']), (result) => {
                    this.currentRecipe.ingredient = result;
                    callback();
                    this.notifyRecipeUpdateObservers();
                }, errorCallback);
            }, errorCallback);
        } catch(err) {
            console.log(err);
        }
    },
    getRecipe() {
        return this.currentRecipe;
    },
    getTasteCharacteristics() {
        return recipeTasteCharacteristics.map(it => {
            return {category: RECIPE_CHARACTERISTIC_TASTE, value: it, code: it, isActive: isRecipeCharacteristicActive(this.currentRecipe, RECIPE_CHARACTERISTIC_TASTE, it)};
        });
    },
    getDateLastUpdate() {
        return this.lastRecipeUpdateTime;
    },
    registerItemUpdateObserver(observer) {
        this.recipeUpdateObservers.push(observer);
    },
    unRegisterItemUpdateObserver(observer) {
        this.recipeUpdateObservers = this.recipeUpdateObservers.filter(productUpdateFunction => productUpdateFunction !== observer);
    },
    notifyRecipeUpdateObservers() {
        this.recipeUpdateObservers.forEach(productUpdateFunction => productUpdateFunction(this.currentRecipe));
    },
    updateItemLocalization(locale, value) {
        try {
            i18n.update(`recipe.item.type.${this.currentRecipe.recipeType}`, value, locale);
            if (this.currentRecipe.locales && this.currentRecipe.locales.length) {
                const index = this.currentRecipe.locales.findIndex(it => it.localeCode === locale);
                index >= 0 ?
                    this.currentRecipe.locales[index].localeValue = value
                    :
                    this.currentRecipe.locales.push({localeCode: locale, localeValue:value});
            } else {
                this.currentRecipe.locales.push({localeCode: locale, localeValue: value});
            }
            if(checkItemLocaleCode(this.currentRecipe.recipeType)) {
                updateValueSevice.updateLocaleValue(locale, `recipe.item.type.${this.currentRecipe.recipeType}`, () => {
                    this.lastRecipeUpdateTime = Date.now();
                    this.notifyRecipeUpdateObservers();
                });
            }
        } catch(err) {
            console.log(err);
        }
    },
    updateCharacteristic(characteristicValue) {
        try {
            const currentCharacteristic = this.currentRecipe.characteristics.find(it => it.value === characteristicValue && it.category === RECIPE_CHARACTERISTIC_TASTE);
            if (currentCharacteristic === undefined) {
                postFetchRequest(makeUrl([RECIPES_URL, this.currentRecipe.id, '/characteristic/']), {
                    category: RECIPE_CHARACTERISTIC_TASTE,
                    value: characteristicValue,
                },
                (response) => {
                    this.currentRecipe.characteristics.push(response);
                    this.lastRecipeUpdateTime = Date.now();
                    this.notifyRecipeUpdateObservers();
                }, errorCallback);
            } else {
                deleteFetchRequest(makeUrl([RECIPES_URL, this.currentRecipe.id, `/characteristic/${currentCharacteristic.id}/`]),
                    (response) => {
                        this.currentRecipe.characteristics = removeElementFromArray(this.currentRecipe.characteristics, (it) => {
                            if (it.id === currentCharacteristic.id) return false;
                            return true;
                        });
                        this.lastRecipeUpdateTime = Date.now();
                        this.notifyRecipeUpdateObservers();
                    }, errorCallback);
            }
        } catch(err) {
            console.log(err);
        }
    },
    addIngredient(ingredient) {
        try {
            postFetchRequest(makeUrl([RECIPES_URL, this.currentRecipe.id, '/ingredient/']), ingredient, 
                (response) => {
                    this.currentRecipe.ingredient.push(response);
                    this.lastRecipeUpdateTime = Date.now();
                    this.notifyRecipeUpdateObservers();
                }, errorCallback);
        } catch(err) {
            console.log(err);
        }
    },
    deleteIngredient(ingredientId) {
        try {
            this.currentRecipe.ingredient = this.currentRecipe.ingredient.filter(it => it.id !== ingredientId);
            deleteFetchRequest(makeUrl([RECIPES_URL, this.currentRecipe.id, '/ingredient/', ingredientId, '/']), successCallbackEmpty, errorCallback);
            this.lastRecipeUpdateTime = Date.now();
            this.notifyRecipeUpdateObservers();
        } catch(err) {
            console.log(err);
        }
    },
    deleteIngredientForm(ingredientId, formCode) {
        try {
            const currentIngredient = this.currentRecipe.ingredient.find(it => it.id === ingredientId);
            currentIngredient.ingredientParts[0].ingredientForm = currentIngredient.ingredientParts[0].ingredientForm.filter(it => it !== formCode);
            postFetchRequest(makeUrl([RECIPES_URL, this.currentRecipe.id, '/ingredient/', currentIngredient.id, '/']), currentIngredient, successCallbackEmpty, errorCallback, true);
            this.lastRecipeUpdateTime = Date.now();
            this.notifyRecipeUpdateObservers();
        } catch(err) {
            console.log(err);
        }
    },
    addIngredientForm(ingredientId, formCode) {
        try {
            const currentIngredient = this.currentRecipe.ingredient.find(it => it.id === ingredientId);
            currentIngredient.ingredientParts[0].ingredientForm.push(formCode);
            updateValueSevice.updateItemValue(() => {
                postFetchRequest(makeUrl([RECIPES_URL, this.currentRecipe.id, '/ingredient/', currentIngredient.id, '/']), currentIngredient, () => {
                    this.lastRecipeUpdateTime = Date.now();
                    this.notifyRecipeUpdateObservers();
                }, errorCallback, true);
            });
            this.notifyRecipeUpdateObservers();
        } catch(err) {
            console.log(err);
        }
    },
    updateRecipeType(value) {
        this.currentRecipe.recipeType = value;
        postFetchRequest(makeUrl([RECIPES_URL, this.currentRecipe.id, '/']), {recipeType: value}, successCallbackEmpty, errorCallback);
        this.lastRecipeUpdateTime = Date.now();
        this.notifyRecipeUpdateObservers();
    },
    cleanService() {
        this.currentRecipe = {};
    },
};
