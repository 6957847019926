import { PREFERENCE_TYPE_CEREAL_MIX } from "../constant";
import { i18n } from "../i18n";
import { GET_CURRENT_USER_REQUEST, RECIPE_SCHEME_URL, getFetchRequest, postFetchRequest } from "../store/requests";
import { preferenceSaveService } from "./preferenceSaveService";
import { preferenceUpdateCerealMixService } from "./preferenceUpdateCerealMixService";
import { productsStorage } from "./productsStorage";
import { recipeAddIngredientService } from "./recipeAddIngredientsService";
import {errorCallback, makeUrl, successCallbackEmpty} from "../util";

const createStringOfNames = (listOfIngredientParts) => {
    const result = [];
    listOfIngredientParts.forEach(it => result.push(i18n.get(`product.item.code.${it.productCode}`)));
    return result.join(', ');
};

const createStringOfCodes = (listOfIngredientParts) => {
    const result = [];
    listOfIngredientParts.forEach(it => result.push(it.productCode));
    return result.join(',');
};

const createCerealMixes = (response) => {
    const result = [];
    response.forEach(it => it.ingredientType === PREFERENCE_TYPE_CEREAL_MIX ? 
        result.push({itemId: createStringOfCodes(it.ingredientParts), itemTitle: createStringOfNames(it.ingredientParts)})
        :
        null);
    return result;
};

export const prepareProductCodes = (listOfIngredients) => {
    const result = [];
    listOfIngredients.forEach(it => result.push({productCode: it.code}));
    return result;
};

export const preferenceCerealMixSelectService = {
    observersList: [],
    possibleItems: [],
    selectedItems: [],
    searchValue: '',
    userId: '',
    notifyItemsChangingObservers() {
        this.observersList.forEach(observer => observer());
    },
    loadItems() {
        getFetchRequest(makeUrl([GET_CURRENT_USER_REQUEST]), (user) => {
            this.userId = user.id;
            getFetchRequest(makeUrl([RECIPE_SCHEME_URL]), (response) => {
                this.possibleItems = createCerealMixes(response);
                this.notifyItemsChangingObservers();
            }, errorCallback);
        }, errorCallback);
    },
    getPossibleItems() {
        if(this.searchValue) {
            return this.possibleItems.filter(it => it.itemTitle.includes(this.searchValue));
        }
        return this.possibleItems;
    },
    getCurrentItems() {
        if(preferenceSaveService.getPreference().preferenceItemCodes && preferenceSaveService.getPreference().preferenceItemCodes.length) {
            this.selectedItems = [];
            preferenceSaveService.getPreference().preferenceItemCodes.forEach(it => 
                this.selectedItems.push({id: it.itemCode, name: i18n.get(`product.item.code.${it.itemCode}`)}));
        }
        return this.selectedItems;
    },
    deleteItem() {
        this.selectedItems = [];
        preferenceSaveService.fillPreferenceItemCerealMix();
        this.notifyItemsChangingObservers();
    },
    addItem(itemId) {
        if(itemId.includes(',')) {
            const cerealMixesArray = itemId.split(',');
            recipeAddIngredientService.deleteItem('', true);
            preferenceUpdateCerealMixService.deleteItem('', true);
            for(let cerealMix of cerealMixesArray) {
                recipeAddIngredientService.addItem(productsStorage.getItems(true).find(it=> it.productCode === cerealMix).id);
                preferenceUpdateCerealMixService.addItem(productsStorage.getItems(true).find(it=> it.productCode === cerealMix).id);
            }
        } else {
            this.selectedItems.push(productsStorage.getItems(true).find(it=> it.id === itemId));
        }
        this.notifyItemsChangingObservers();
    },
    registerItemsChangingObserver(observer) {
        this.observersList.push(observer);
    },
    unRegisterItemsChangingObserver(observer) {
        try {
            this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
        } catch (err) {
            console.log(err);
        }
    },
    hasExcludedItemsBlock() {
        return false;
    },
    setSearchFilterValue(searchValue) {
        this.searchValue = searchValue;
        this.notifyItemsChangingObservers();
    },
    getSearchString() {
        return this.searchValue;
    },
    getPopupKey() {
        return '';
    },
    getButtonTitle() {
        return i18n.get("app.page.preference.addCerealMix");
    },
    addNewCerealMix(currentService) {
        const preparedIdArray = [];
        const preparedNameArray = [];
        currentService.getCurrentItems().forEach(it => {
            preparedIdArray.push(it.code);
            preparedNameArray.push(it.itemTitle);
        });
        this.possibleItems.push({itemId: preparedIdArray.join(','), itemTitle: preparedNameArray.join(', ')});
        const preparedBody = {
            ingredientType: PREFERENCE_TYPE_CEREAL_MIX,
            ingredientParts: prepareProductCodes(currentService.getCurrentItems()),
        };
        postFetchRequest(makeUrl([RECIPE_SCHEME_URL]), preparedBody, successCallbackEmpty, errorCallback, true);
        this.notifyItemsChangingObservers();
        currentService.deleteItem('', true);
    },
};
