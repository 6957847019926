import { POPUP_KEY_CREATE_INGREDIENT, PREFERENCE_TYPE_PRODUCT } from "../constant";
import { i18n } from "../i18n";
import { createPossibleItemCodeValues } from "./preferenceSaveService";
import { productsService } from "./productsService";
import { productsStorage } from "./productsStorage";

const transformProductsList = (formsList) => {
    let resultArray = [];
    formsList.forEach(it => resultArray.push({itemId: it.id, itemTitle: productsService.getLocalization(i18n.APP_LOCALE, it.productCode, true), 
        included: true, code: it.productCode}));
    return resultArray;
};

export const recipeAddIngredientService = {
    observersList: [],
    possibleProducts: transformProductsList(productsStorage.getItems(true)),
    selectedProducts: [],
    ingredientType: PREFERENCE_TYPE_PRODUCT,
    filter: '',
    notifyItemsChangingObservers() {
        this.observersList.forEach(observer => observer());
    },
    getPossibleItems() {
        this.possibleProducts = transformProductsList(createPossibleItemCodeValues(this.ingredientType));
        if(this.filter) {
            return this.possibleProducts.filter(it => it.itemTitle.toLowerCase().includes(this.filter.toLowerCase()));
        }
        return this.possibleProducts;
    },
    getCurrentItems() {
        return this.selectedProducts;
    },
    deleteItem(productId, deleteAll) {
        this.selectedProducts = deleteAll ? [] : this.selectedProducts.filter(it => it.itemId !== productId);
        this.notifyItemsChangingObservers();
    },
    addItem(productId) {
        const currentProduct = productsStorage.getById(productId);
        this.selectedProducts.push({itemId: currentProduct.id, code: currentProduct.productCode,
            itemTitle: productsService.getLocalization(i18n.APP_LOCALE, currentProduct.productCode, true), 
            included: true});
        this.setSearchFilterValue('');
        this.notifyItemsChangingObservers();
    },
    registerItemsChangingObserver(observer) {
        this.observersList.push(observer);
    },
    unRegisterItemsChangingObserver(observer) {
        try {
            this.observersList = this.observersList.filter(observerItem => observerItem !== observer);
        } catch (err) {
            console.log(err);
        }
    },
    hasExcludedItemsBlock() {
        return false;
    },
    setSearchFilterValue(searchValue) {
        this.filter = searchValue;
        this.getPossibleItems();
        this.notifyItemsChangingObservers();
    },
    getSearchString() {
        return this.filter;
    },
    getPopupKey() {
        return POPUP_KEY_CREATE_INGREDIENT;
    },
    getButtonTitle() {
        return i18n.get('app.page.recipe.addProduct');
    },
    getIngredientType() {
        return this.ingredientType;
    },
    changeIngredientType(value) {
        this.ingredientType = value;
        this.selectedProducts = [];
        this.notifyItemsChangingObservers();
    },
    loadItems() {
        return;
    },
};
