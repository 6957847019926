import React, { useState, useEffect } from 'react';
import { GET_APP_ROLES, GET_USER_REQUEST, getFetchRequest, postFetchRequest } from '../store/requests';
import { ADMIN_CONSOLE_BUSINESS_OBJECT, ADMIN_ROLE, AUTH_PERMISSION, CABINET_BUSINESS_OBJECT, ERROR_WRONG_EMAIL_TYPE, ERROR_WRONG_ROLE_TYPE, GUEST_ROLE, LOCALE_RU, PERMISSION_LEVEL_BUSINESS_OBJECT, PERMISSION_LEVEL_COMPONENT, TABLET_WIDTH, currentPermissions } from '../constant';
import { ErrorHandler } from './ErrorHandler';
import { ChevronIcon } from '../appearence/icons/chevron_icon';
import Select from './sharedComponents/Select';
import { i18n } from '../i18n';
import useWindowDimensions from '../hooks/useWindowDimension';
import ProfilesTab from './ProfilesTab';
import CustomCheckbox from './CustomCheckbox';
import PhoneInput from './sharedComponents/PhoneInput';
import {errorCallback, makeUrl} from "../util";


const UsersTable = ({usersList, isTab, handleChange = null, findCheckedValues = null}) => {
    try {
        return (
            <table className="block__table users__table">
                <thead>
                    <tr className="users__table-header">
                        {isTab ? <th></th> : null}
                        <th className={`users__table-item ${isTab ? 'users__table-item_sticky users__table-item_modal' : ''}`}>
                            {i18n.get('app.page.user.name')}/id
                        </th>
                        <th className={`users__table-item ${isTab ? 'users__table-item_sticky users__table-item_modal' : ''}`}>
                            {i18n.get('app.page.user.phone')}/{i18n.get('app.page.user.post')}
                        </th>
                        <th className={`users__table-item ${isTab ? 'users__table-item_sticky users__table-item_modal' : ''}`}>
                            {i18n.get('app.page.user.role')}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {usersList && usersList.length ? 
                        usersList.map((it, index) => 
                            isTab || index < 4 ?
                                <tr className="users__table-row" key={it.id}>
                                    {isTab ? 
                                        <td className="users__tab-checkbox">
                                            <CustomCheckbox handleChange={handleChange} checked={findCheckedValues(it.id)} values={{value: it}}/> 
                                        </td>
                                        : 
                                        null}
                                    <td className="users__table-item">
                                        {it.name}
                                        {it.name && !isTab ? null : <p className={isTab ? "block__bullet users__tab-id users__tab-content" : "users__tab-data"}>{it.id}</p>}
                                    </td>
                                    <td className="users__table-item">
                                        {it.phone}
                                        {it.phone && !isTab ? null : <p className={isTab ? "users__tab-content" : "users__tab-data"}>{it.email}</p>}
                                    </td>
                                    <td className="users__table-item">{i18n.get(`appRoles.scheme.userRoles.${it.appRole.code}`)}</td>
                                </tr>
                                :
                                '',
                        )
                        :
                        null}
                </tbody>
            </table>
        );
    } catch(err) {
        return <ErrorHandler titleValue={i18n.get('app.page.profile.emptyData')}/>;
    }
};

const getValueOfPermissions = (arrayOfPermissions, entryPoint, key) => {
    let result = false;
    if (arrayOfPermissions.length) {
        entryPoint === CABINET_BUSINESS_OBJECT || entryPoint === ADMIN_CONSOLE_BUSINESS_OBJECT ?
            arrayOfPermissions.forEach(it => it.targetName === entryPoint ? result = it[key] : null) :
            arrayOfPermissions.forEach(it => it.entryPoint === entryPoint ? result = it[key] : null);
    }
    return result;
};

const sortArrayOfObjects = (array, propertyName) => {
    const coppiedArray = [...array];
    coppiedArray.sort((a, b) => {
        if (a.level === PERMISSION_LEVEL_COMPONENT) { return -1; }
        if (b.level === PERMISSION_LEVEL_COMPONENT) { return 1; }
        if (a[propertyName] < b[propertyName]) { return -1; }
        if (a[propertyName] > b[propertyName]) { return 1; }
        return 0;
    });
    return coppiedArray;
};

const sortRolesByCode = (appRoles) => {
    console.log(appRoles);
    let result = [];
    for (let i = 0; i < appRoles.length; i++) {
        appRoles[i].name = i18n.get(`appRoles.scheme.userRoles.${appRoles[i].code}`);
        if (appRoles[i].code !== GUEST_ROLE && appRoles[i].code !== ADMIN_ROLE) {
            result.push(appRoles[i]);
        }
    }
    result = sortArrayOfObjects(result, 'code');
    const lastIndex = appRoles.findIndex((it) => it.code === ADMIN_ROLE);
    const firstIndex = appRoles.findIndex((it) => it.code === GUEST_ROLE);
    result.push(appRoles[lastIndex]);
    result.unshift(appRoles[firstIndex]);
    console.log(result);
    return result;
};

const PermissionsListElem = ({ listOfRoles, row, handleChangePermission }) => {
    try {
        return (
            currentPermissions.map((permissions, index) => {
                return (
                    <tr key={permissions.key} className={index === currentPermissions.length - 1 ? "users__table-roles-row" : ""}>
                        <td className="users__table-item users__table-permission">
                            {i18n.get(`appRoles.scheme.permissions.${permissions.key}`)}
                        </td>
                        {listOfRoles.length ? 
                            listOfRoles.map((it) => {
                                return (
                                    <td key={it.id} className="users__table-item">
                                        <CustomCheckbox checked={it.permissions ? getValueOfPermissions(it.permissions, row.entryPoint, permissions.key) : false}
                                            handleChange={(e) => handleChangePermission(e, it, permissions.key, row)} roles={true}/>
                                    </td>
                                );
                            }) 
                            : 
                            ''}
                    </tr>
                );
            })
        );
    } catch(err) {
        console.log(err);
        return null;
    }
};

const makeSmallStringInTabletVersion = (string) => {
    let result = '';
    if(string === i18n.get('app.page.appRole.businessObjectAndRoles.user_preference')) {
        result = i18n.get('app.page.appRole.businessObjectAndRoles.user_preference');
    } else if(string === i18n.get('app.page.appRole.businessObjectAndRoles.cabinet')) {
        result = i18n.get('app.page.appRole.businessObjectAndRoles.cabinet').split(' ').reverse()[0];
    } else if(string === i18n.get('app.page.appRole.businessObjectAndRoles.admin_console')) {
        const array = i18n.get('app.page.appRole.businessObjectAndRoles.admin_console').split(' ');
        result = array.slice(-2).join(' ');
    } else {
        result = string.split(' ')[0];
    }
    return result;
};

const logsFor = (it) => {
    console.log(it);
    return null;
};

const RolesTable = ({appRolesList, handleChangePermission, handleSetRoleIsOpen, rolesInterfaceList, setRolesInterfaceList, width}) => {
    try {
        return (
            <div className="users__table-wrapper">
                <table className="block__table users__table users__table-roles">
                    <thead>
                        <tr className="users__table-header users__table-roles-header">
                            <th className="users__table-item users__table-item_sticky">
                                {width <= TABLET_WIDTH ?
                                    i18n.get('app.page.appRole.businessObjectAndRoles.object')
                                    :
                                    <>
                                        <span className="users__table-item_capitalize">{i18n.get('app.page.appRole.businessObjectAndRoles.businessObject')}</span>
                                        /
                                        <span className="users__table-item_capitalize">{i18n.get('app.page.appRole.businessObjectAndRoles.roles')}</span>
                                    </>}
                            </th>
                            {sortRolesByCode(appRolesList).map((it, index) => (
                                <th className="users__table-item users__table-item_sticky" key={index + 'header'}>
                                    {logsFor(it)}
                                    {width <= TABLET_WIDTH ?
                                        makeSmallStringInTabletVersion(i18n.get(`appRoles.scheme.userRoles.${it.code}`))
                                        :
                                        i18n.get(`appRoles.scheme.userRoles.${it.code}`)}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {rolesInterfaceList.map((row, index) => (
                            <React.Fragment key={index + 'rolesList'}>
                                {row.targetName !== AUTH_PERMISSION && <tr className={row.isOpen ? "" : "users__table-roles-row"} 
                                    key={row.entryPoint} >
                                    <td className="users__table-item users__table-title" onClick={() => row.level !== PERMISSION_LEVEL_COMPONENT ? handleSetRoleIsOpen(rolesInterfaceList, setRolesInterfaceList, index) : null}>
                                        {width <= TABLET_WIDTH ?
                                            makeSmallStringInTabletVersion(i18n.get(`app.page.appRole.businessObjectAndRoles.${row.targetName}`))
                                            :
                                            i18n.get(`app.page.appRole.businessObjectAndRoles.${row.targetName}`)}
                                    </td>
                                    {appRolesList.map((role, indexItem) => {
                                        return (
                                            <td key={role.id} className="users__table-item">
                                                {row.level === PERMISSION_LEVEL_COMPONENT ?
                                                    <CustomCheckbox handleChange={(e) => handleChangePermission(e, role, row.targetName, row)} roles={true}
                                                        checked={role.permissions.length ? getValueOfPermissions(role.permissions, row.targetName, 'hasAccess') : false} />
                                                    : indexItem === 0 ?
                                                        <span onClick={() => row.level !== PERMISSION_LEVEL_COMPONENT ? handleSetRoleIsOpen(rolesInterfaceList, setRolesInterfaceList, index) : null}>
                                                            <ChevronIcon nameOfClass={row.isOpen ? 'users__table-icon_active' : 'users__table-icon'} />
                                                        </span> 
                                                        : 
                                                        null}
                                            </td>
                                        );
                                    })}

                                </tr>}
                                {row.isOpen ? <PermissionsListElem row={row} listOfRoles={appRolesList} handleChangePermission={handleChangePermission} /> : ''}
                            </React.Fragment>))}
                    </tbody>
                </table>
            </div>
        );
    } catch(err) {
        console.log(err);
        return <ErrorHandler titleValue={i18n.get('app.page.profile.emptyData')}/>;
    }
};

const findRoleCodeById = (role, rolesList) => {
    let result = '';
    if(role.appRoleId) {
        const roleCode = rolesList.find(it => it.id === role.appRoleId).code;
        result = roleCode ? i18n.get(`appRoles.scheme.userRoles.${roleCode}`) : '';
    }
    return result;
};

const UsersPage = ({currentUserData}) => {
    const [inviteNewUserForm, setInviteNewUserForm] = useState({email: '', appRoleId:'', locale: LOCALE_RU});
    const [phoneInput, setPhoneInput] = useState('');
    const [usersList, setUsersList] = useState([]);
    const [appRolesList, setAppRolesList] = useState({fullList: [], activeList:[]});
    const [error, setError] = useState({type:'', message:''});
    const [usersListModalIsOpen, setUsersListModalIsOpen] = useState(false);
    const [rolesInterfaceList, setRolesInterfaceList] = useState([]);
    const [currentUserRole, setCurrentUserRole] = useState({});
    const { width } = useWindowDimensions();

    const successChangePermissionCallback = (role, preparedData) => {
        const index = appRolesList.fullList.findIndex((it) => it.id === role.id);
        const coppiedArray = [...appRolesList.fullList];
        coppiedArray[index] = preparedData;
        setAppRolesList({fullList: coppiedArray, activeList:[]});
    };

    const makeInterfacesValue = (response) => {
        const dataForAll = [];
        const sortedData = sortRolesByCode(response);
        sortedData[0].permissions.forEach((it) => {
            dataForAll.push({ ...it, isOpen: false});
        });
        setRolesInterfaceList(sortArrayOfObjects(dataForAll, 'title'));
        setAppRolesList({...appRolesList, fullList: sortedData});
    };

    const handleSetRoleIsOpen = (group, setGroup, index) => {
        group[index].isOpen = !group[index].isOpen;
        setGroup([...group]);
    };

    const handleChangePermission = (e, role, permissionKey, row) => {
        if(currentUserRole.code === ADMIN_ROLE) {
            const preparedData = {...role};
            if (row.level === PERMISSION_LEVEL_BUSINESS_OBJECT) {
                preparedData.permissions.forEach((it) => {
                    if (row.entryPoint === it.entryPoint) {
                        it[permissionKey] = e.target.checked;
                    }
                });
            } else {
                preparedData.permissions.forEach((it) => {
                    if (row.targetName === it.targetName) {
                        it.hasAccess = e.target.checked;
                    }
                });
            }
            postFetchRequest(makeUrl([GET_APP_ROLES, preparedData.id, '/']), preparedData, () => successChangePermissionCallback(role, preparedData), errorCallback, true);
        }
    };


    const successGetAppRoles = (response) => {
        if (response.length) {
            makeInterfacesValue(response);
        }
    };

    useEffect(() => {
        setCurrentUserRole(currentUserData.appRole);
    }, []);

    useEffect(() => {
        if(currentUserRole.code) {
            getFetchRequest(makeUrl([GET_USER_REQUEST]), (response) => setUsersList(response), errorCallback);
            getFetchRequest(makeUrl([GET_APP_ROLES]), successGetAppRoles, errorCallback);
        }
    },[currentUserRole]);

    const handleChangeInviteNewUserFormEmail = (e) => {
        inviteNewUserForm[e.target.name] = e.target.value;
        setInviteNewUserForm({...inviteNewUserForm});
    };

    const handleChangeInviteNewUserFormRole = (role) => {
        inviteNewUserForm['appRoleId'] = role.id;
        setInviteNewUserForm({...inviteNewUserForm});
    };

    const successCallbackInviteUser = (response) => {
        if(response.ok) {
            setInviteNewUserForm({email: '', appRoleId:'', locale: LOCALE_RU});
            setPhoneInput('');
            getFetchRequest(makeUrl([GET_USER_REQUEST]), (response) => setUsersList(response), errorCallback);
        }
    };

    const handleInviteUser = () => {
        if((inviteNewUserForm.email || phoneInput) && inviteNewUserForm.appRoleId ) {
            const preparedData = {
                appRoleId: inviteNewUserForm.appRoleId,
                locale: inviteNewUserForm.locale,
            };
            phoneInput ? preparedData.phone = `+7${phoneInput}` : preparedData.email = inviteNewUserForm.email;
            postFetchRequest(makeUrl([GET_USER_REQUEST]), preparedData, successCallbackInviteUser, errorCallback, true);
        } else {
            setError({type: inviteNewUserForm.email ? ERROR_WRONG_ROLE_TYPE : ERROR_WRONG_EMAIL_TYPE, message:''});
        }
    };

    return (
        <section className="workspace">
            {usersListModalIsOpen ? 
                <ProfilesTab setUsersListModalIsOpen={setUsersListModalIsOpen} usersList={usersList} setUsersList={setUsersList} UsersTable={UsersTable}/>
                :
                <>
                    <h1 className="users__title block__title-h2">{i18n.get('app.page.user.title')}</h1>
                    <div className="users__wrapper">
                        <article className="users__article">
                            <h2 className="users__subtitle users__subtitle-send block__title-h3">{i18n.get('app.page.user.sendInvite')}</h2>
                            <form className="users__form">
                                <label className="block__text users__label" >
                                    <span className="product__form-label_text">{i18n.get('app.page.user.email')}</span>
                                    <input className={`users__input ${error.type === ERROR_WRONG_EMAIL_TYPE ? 'login__input_error' : ''}`} 
                                        value={inviteNewUserForm.email}
                                        name="email"
                                        onChange={handleChangeInviteNewUserFormEmail}/>
                                </label>
                                <PhoneInput phone={phoneInput} setPhoneInput={setPhoneInput} error={error} />
                                <p className="block__text users__content">{i18n.get('app.page.user.selectRole')}</p>
                                <Select handleChangeValue={handleChangeInviteNewUserFormRole} listOfOptions={appRolesList} setListOfOptions={setAppRolesList}
                                    titleValue={findRoleCodeById(inviteNewUserForm, appRolesList.fullList)} activeOptionList={[]}/>
                                <button type="button" onClick={handleInviteUser}
                                    className={`block__button button users__button 
                                        ${(inviteNewUserForm.email || phoneInput) && inviteNewUserForm.appRoleId ? '' : 'button_disabled'}`}>
                                    <span className="button__content">{i18n.get('app.page.user.send')}</span>
                                </button>
                            </form>
                        </article>
                        <article className="users__article users__article-list">
                            <h2 className="users__subtitle block__title-h3">{i18n.get('app.page.user.residentsData')}</h2>
                            <UsersTable usersList={usersList} isTab={false} />
                            <p className="block__table users__more navigation-link" onClick={() => setUsersListModalIsOpen(true)}>
                                {i18n.get('app.page.user.more')}
                            </p>
                        </article>
                    </div>
                    
                    <article className="users__article users__article_full-width">
                        <h2 className="users__subtitle block__title-h3">{i18n.get('app.page.user.roleAssignment')}</h2>
                        <RolesTable appRolesList={appRolesList.fullList} rolesInterfaceList={rolesInterfaceList} handleChangePermission={handleChangePermission}
                            handleSetRoleIsOpen={handleSetRoleIsOpen} setRolesInterfaceList={setRolesInterfaceList} width={width}/>
                    </article>
                </>}
        </section>
    );
};

export default UsersPage;
